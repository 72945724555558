import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import styled from "styled-components"
import ContactMain from "../pagesParts/contact/ContactMain"
import PageHeader from "../components/pageHeader/PageHeader"

const StyledMainWrapper = styled.main`
  margin-top: 60px;
`

export default function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="Il y a d'excellentes sociétés de service informatique dans la région lémanique, 
        mais peu suivent leurs clients avec l'attention particulière que nous vous réservons."
      />
      <StyledMainWrapper>
        <PageHeader
          heading="Contact"
          subheading="Il y a d'excellentes sociétés de service informatique dans la région lémanique, 
          mais peu suivent leurs clients avec l'attention particulière que nous vous réservons."
        />
        <ContactMain />
      </StyledMainWrapper>
    </Layout>
  )
}
